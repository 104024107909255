@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Poppins:wght@100;400;600;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  background-color: #010026;
  @apply text-white;
}
